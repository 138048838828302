import { globalConfig } from '../config'

export const logoutUser = userProfile => {
  if (!userProfile.email) {
    return [
      localStorage.clear('userInfo', userProfile.email),
      globalConfig.logoutUrl,
    ]
  }
}

export function debounce(func, wait, immediate) {
  var timeout;

  return (...args) => {
    var context = this;

    var later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export function checkIsProvider(userMetadata) {
  return Number(userMetadata?.membershipType) === 3001
}