import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'

import { Auth } from './components/Auth/Auth'
import { Loading } from './components/Loading'
import ErrorBoundary from './components/ErrorBoundary'

import 'bootstrap/dist/css/bootstrap.min.css'
import MainDashBoard from './components/MainDashboard/MainDashboard'

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 5000,
      },
    },
  })

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : !isAuthenticated ? (
        loginWithRedirect()
      ) : (
        <QueryClientProvider client={queryClient}>
          <Auth>
            <ToastProvider>
              <BrowserRouter>
                <ErrorBoundary>
                  <MainDashBoard />
                </ErrorBoundary>
              </BrowserRouter>
            </ToastProvider>
          </Auth>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      )}
    </>
  )
}

export default App
