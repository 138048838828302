import React from 'react'
import styled from 'styled-components'
import RouterConfig from '../NavigationComponent/RouterConfig'
import { Layout } from './Layout/Layout'

const MainDashBoard = () => {
  return (
    <section>
      <Layout header="Home">
        <Wrapper>
          <RouterConfig />
        </Wrapper>
      </Layout>
    </section>
  )
}

const Wrapper = styled.div`
  gap: 24px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
`

export default MainDashBoard
