import React, { lazy, Suspense, useContext } from 'react'
import { Switch, Route } from 'react-router-dom'

import * as ROUTES from './constants'
import { AuthContext } from '../Auth/Auth'
import { Loading } from '../Loading'

const Home = lazy(() => import('../DashBoards/DashBoardComponents/Home'))
const HomeMap = lazy(() => import('../DashBoards/DashBoardComponents/HomeMap'))

const ReporterForm = lazy(() =>
  import('../DashBoards/DashBoardForms/ReporterForm')
)
const EditorForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EditorForm')
)
const BroadcasterForm = lazy(() =>
  import('../DashBoards/DashBoardForms/BroadcasterForm')
)
const ProducerForm = lazy(() =>
  import('../DashBoards/DashBoardForms/ProducerForm')
)
const AudienceForm = lazy(() =>
  import('../DashBoards/DashBoardForms/AudienceForm')
)
const ArticleForm = lazy(() =>
   import('../DashBoards/DashBoardForms/ArticleForm')
 )
const WorkflowForm = lazy(() =>
   import('../DashBoards/DashBoardForms/WorkflowForm')
 )
const WorkflowEventForm = lazy(() =>
   import('../DashBoards/DashBoardForms/WorkflowEventForm')
 )
 const ProjectForm = lazy(() =>
   import('../DashBoards/DashBoardForms/ProjectForm')
 )
 const ContentForm = lazy(() =>
   import('../DashBoards/DashBoardForms/ContentForm')
 )
 const NetworkContentForm = lazy(() =>
   import('../DashBoards/DashBoardForms/NetworkContentForm')
 )

 const TriggerForm = lazy(() =>
   import('../DashBoards/DashBoardForms/TriggerForm')
 )
  const TransformationForm = lazy(() =>
   import('../DashBoards/DashBoardForms/TransformationForm')
 )
  const ContentInputSourceForm = lazy(() =>
   import('../DashBoards/DashBoardForms/ContentInputSourceForm')
 )
 //programming
  const ProgramForm = lazy(() =>
   import('../DashBoards/DashBoardForms/ProgramForm')
 )
  const SegmentForm = lazy(() =>
   import('../DashBoards/DashBoardForms/SegmentForm')
 )
  const ShowForm = lazy(() =>
   import('../DashBoards/DashBoardForms/ShowForm')
 )
  const ServiceForm = lazy(() =>
   import('../DashBoards/DashBoardForms/ServiceForm')
 )
const PrivateRoute = lazy(() => import('./PrivateRoute'))
// const AppointmentsCalendar = lazy(() =>
//   import('../DashBoards/DashBoardComponents/AppointmentsCalendar')
// )
// const EditorAppointmentsCalendar = lazy(() =>
//   import('../DashBoards/DashBoardComponents/EditorAppointmentsCalendar')
// )
// const VisitsCalendar = lazy(() =>
//   import('../DashBoards/DashBoardComponents/VisitsCalendar')
// )
// const Labs = lazy(() => import('../DashBoards/DashBoardComponents/Labs'))
const Reporters = lazy(() =>
   import('../DashBoards/DashBoardComponents/Reporters')
 )
 const Editors = lazy(() =>
   import('../DashBoards/DashBoardComponents/Editors')
 )
 const Broadcasters = lazy(() =>
   import('../DashBoards/DashBoardComponents/Broadcasters')
 )
 const Producers = lazy(() =>
   import('../DashBoards/DashBoardComponents/Producers')
 )
 const Audiences = lazy(() =>
   import('../DashBoards/DashBoardComponents/Audiences')
 )
 const Articles = lazy(() =>
   import('../DashBoards/DashBoardComponents/Articles')
 )
 const Workflows = lazy(() =>
   import('../DashBoards/DashBoardComponents/Workflows')
 )
 const WorkflowEvents = lazy(() =>
   import('../DashBoards/DashBoardComponents/WorkflowEvents')
 )
 const Projects = lazy(() =>
   import('../DashBoards/DashBoardComponents/Projects')
 )
 const Content = lazy(() =>
   import('../DashBoards/DashBoardComponents/Content')
 )
 const NetworkContent = lazy(() =>
   import('../DashBoards/DashBoardComponents/NetworkContent')
 )
 const Triggers = lazy(() =>
   import('../DashBoards/DashBoardComponents/Triggers')
 )
 const Transformations = lazy(() =>
   import('../DashBoards/DashBoardComponents/Transformations')
 )
 const ContentInputSources = lazy(() =>
   import('../DashBoards/DashBoardComponents/ContentInputSources')
 )
 //programming
 const Programs = lazy(() =>
   import('../DashBoards/DashBoardComponents/Programs')
 )
 const Segments = lazy(() =>
   import('../DashBoards/DashBoardComponents/Segments')
 )
 const Shows = lazy(() =>
   import('../DashBoards/DashBoardComponents/Shows')
 )
 const Services = lazy(() =>
   import('../DashBoards/DashBoardComponents/Services')
 )
const Payments = lazy(() =>
  import('../DashBoards/DashBoardComponents/Payments')
)
// const Prescriptions = lazy(() =>
//   import('../DashBoards/DashBoardComponents/Prescriptions')
// )
// const Visits = lazy(() => import('../DashBoards/DashBoardComponents/Visits'))
const Documents = lazy(() => import('../pages/UserProfile/Documents'))
const Subscribers = lazy(() =>
   import('../DashBoards/DashBoardComponents/Subscribers')
 )
// 
// const CreateEhx = lazy(() => import('../DashBoards/DashBoardForms/CreateEhx'))
// const CreateEhxPoc = lazy(() =>
//   import('../DashBoards/DashBoardForms/CreateEhxPoc')
// )
// const EhxLabForm = lazy(() => import('../DashBoards/DashBoardForms/EhxLabForm'))
const EhxPaymentForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxPaymentForm')
)
// const EhxPrescriptionForm = lazy(() =>
//   import('../DashBoards/DashBoardForms/EhxPrescriptionForm')
// )

// const EditorScheduleForm = lazy(() =>
//   import('../DashBoards/DashBoardForms/EditorScheduleForm')
// )
// const EhxUserProfileForm = lazy(() =>
//   import('../DashBoards/DashBoardForms/EhxUserProfileForm')
// )
// const DashboardContent = lazy(() =>
//   import('../DashBoards/DashboardContent/DashboardContent')
// )
const EhxChatForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxChatForm')
)
const EditorSettings = lazy(() =>
  import('../pages/UserProfile/EditorSettings')
)
const SubscriberSettings = lazy(() =>
  import('../pages/UserProfile/SubscriberSettings')
)
const Notifications = lazy(() => import('../pages/UserProfile/Notifications'))
const Accounts = lazy(() => import('../pages/UserProfile/Accounts'))
const UserProfile = lazy(() => import('../pages/UserProfile/UserProfile'))
// const HealthFacilities = lazy(() =>
//   import('../DashBoards/DashBoardComponents/HealthFacilities')
// )
// const EhxHealthCareFacilityForm = lazy(() =>
//   import('../DashBoards/DashBoardForms/EhxHealthCareFacilityForm')
// )
// 
// const EhxAppointmentFormReporterNew = lazy(() =>
//   import('../DashBoards/DashBoardForms/EhxAppointmentForm/Reporter/New')
// )
// const EhxAppointmentFormReporterEdit = lazy(() =>
//   import('../DashBoards/DashBoardForms/EhxAppointmentForm/Reporter/Edit')
// )
// const EhxAppointmentFormEditorNew = lazy(() =>
//   import('../DashBoards/DashBoardForms/EhxAppointmentForm/Editor/New')
// )
// const EhxAppointmentFormEditorEdit = lazy(() =>
//   import('../DashBoards/DashBoardForms/EhxAppointmentForm/Editor/Edit')
// )
// 
const EhxSubscriberForm = lazy(() =>
  import('../DashBoards/DashBoardForms/EhxSubscriberForm')
)
// const EhxVisitFormReporter = lazy(() =>
//   import('../DashBoards/DashBoardForms/EhxVisitFormReporter')
// )
// const EhxVisitFormEditor = lazy(() =>
//   import('../DashBoards/DashBoardForms/EhxVisitFormEditor')
// )

const RouterConfig = ({ sessionObject, membershipType }) => {
  const userMetadata = useContext(AuthContext)

  return (
    <Suspense fallback={<Loading />}>
      <Switch>

        {/**
        <Route path={ROUTES.SUBSCRIBERS}>
          <Subscribers />
        </Route>
        <Route path={ROUTES.VISITS_CALENDAR}>
          <VisitsCalendar />
        </Route>
        <Route path={ROUTES.PRESCRIPTIONS}>
          <Prescriptions />
        </Route>

        <Route path={ROUTES.LABS}>
          <Labs />
        </Route>
        <Route path={ROUTES.PAYMENTS}>
          <Payments />
        </Route>
        <Route path={ROUTES.CREATE_EDITOR}>
          <EditorForm />
        </Route>
        <Route path={ROUTES.CREATE_REPORTER}>
          <ReporterForm />
        </Route>
		**/}
        {/**
         *  APPOINTMENTS RELATED ROUTES

        <Route path={ROUTES.APPOINTMENTS_CALENDAR}>
          <AppointmentsCalendar />
        </Route>
        <Route path={ROUTES.EDITOR_APPOINTMENTS_CALENDAR}>
          <EditorAppointmentsCalendar />
        </Route>
        <Route path={ROUTES.APPOINTMENTS}>
          <Appointments />
        </Route>

        <Route path={ROUTES.CREATE_APPOINTMENT}>
          {parseInt(userMetadata.membershipType) === 3001 ? (
            <EhxAppointmentFormEditorNew />
          ) : (
            <EhxAppointmentFormReporterNew />
          )}
        </Route>
        <Route path={ROUTES.UPDATE_APPOINTMENT}>
          {parseInt(userMetadata.membershipType) === 3001 ? (
            <EhxAppointmentFormEditorEdit />
          ) : (
            <EhxAppointmentFormReporterEdit />
          )}
        </Route>

        <Route path={ROUTES.CREATE_PRESCRIPTION}>
          <EhxPrescriptionForm />
        </Route>
        <Route path={ROUTES.CREATE_LAB}>
          <EhxLabForm />
        </Route>

        <Route path={ROUTES.UPDATE_VISIT}>
          {parseInt(userMetadata.membershipType) === 3001 ? (
            <EhxVisitFormEditor />
          ) : (
            <EhxVisitFormReporter />
          )}
        </Route>
        <Route path={ROUTES.VISITS}>
          <Visits />
        </Route>
        <Route path={ROUTES.MANAGE_PRESCRIPTION}>
          <EhxPrescriptionForm />
        </Route>
        <Route path={ROUTES.MANAGE_LAB}>
          <EhxLabForm />
        </Route>

         */}
        <Route path={ROUTES.REPORTERS}>
          <Reporters />
        </Route>
        <Route path={ROUTES.EDITORS}>
          <Editors />
        </Route>
        <Route path={ROUTES.BROADCASTERS}>
          <Broadcasters />
        </Route>
        <Route path={ROUTES.PRODUCERS}>
          <Producers />
        </Route>
        <Route path={ROUTES.AUDIENCE}>
          <Audiences />
        </Route>
{/* flow */}
        <Route path={ROUTES.ARTICLES}>
          <Articles />
        </Route>
        <Route path={ROUTES.WORKFLOW}>
          <Workflows />
        </Route>
        <Route path={ROUTES.WORKFLOW_EVENTS}>
          <WorkflowEvents />
        </Route>
        <Route path={ROUTES.PROJECTS}>
          <Projects />
        </Route>
{/* content */}

        <Route path={ROUTES.CONTENT}>
          <Content />
        </Route>
        <Route path={ROUTES.NETWORK_CONTENT}>
          <NetworkContent />
        </Route>

        <Route path={ROUTES.TRANSFORMATIONS}>
          <Transformations />
        </Route>
        <Route path={ROUTES.TRIGGERS}>
          <Triggers />
        </Route>    	
        <Route path={ROUTES.CONNECTORS}>
          <ContentInputSources />
        </Route>    	
        <Route path={ROUTES.SERVICES}>
          <Services />
        </Route>    	
{/* programming */}
        <Route path={ROUTES.PROGRAMS}>
          <Programs />
        </Route>
        <Route path={ROUTES.SEGMENTS}>
          <Segments />
        </Route>

        <Route path={ROUTES.SHOWS}>
          <Shows />
        </Route>

{/* manage */}
        <Route path={ROUTES.MANAGE_REPORTER}>
          <ReporterForm />
        </Route>
        <Route path={ROUTES.MANAGE_EDITOR}>
          <EditorForm />
        </Route>
        <Route path={ROUTES.MANAGE_BROADCASTER}>
          <BroadcasterForm />
        </Route>
        <Route path={ROUTES.MANAGE_PRODUCER}>
          <ProducerForm />
        </Route>
        <Route path={ROUTES.MANAGE_AUDIENCE}>
          <AudienceForm />
        </Route>

        <Route path={ROUTES.MANAGE_ARTICLE}>
          <ArticleForm />
        </Route>

                 
        <Route path={ROUTES.CREATE_PAYMENT}>
          <EhxPaymentForm />
        </Route>
        <Route path={ROUTES.UPDATE_REPORTER}>
          <ReporterForm />
        </Route>
        <Route path={ROUTES.MANAGE_SUBSCRIBER}>
          <EhxSubscriberForm />
        </Route>
        <Route path={ROUTES.MANAGE_PAYMENT}>
          <EhxPaymentForm />
        </Route>

        {/* Healthcare facilites
        
        <Route path={ROUTES.UPDATE_HEALTHCARE_FACILITY}>
          <EhxHealthCareFacilityForm />
        </Route>
        <Route path={ROUTES.HEALTHCARE_FACILITY}>
          <HealthFacilities />
        </Route>
 		*/}
        
        <Route path={ROUTES.CHAT}>
          <EhxChatForm />
        </Route>

        {/* User Profile */}
        <Route
          path={ROUTES.USER_PROFILE}
          render={props => (
            <UserProfile {...props} sessionObject={sessionObject} />
          )}
        />

        {/* Editors
                <Route path={ROUTES.EDITOR_SETTINGS}>
          <EditorSettings />
        </Route>
         */}

        <Route path={ROUTES.DOCUMENTS}>
          <Documents sessionObject={sessionObject} />
        </Route>



        <Route path={ROUTES.NOTIFICATIONS}>
          <Notifications />
        </Route>
        <Route path={ROUTES.ACCOUNTS}>
          <Accounts />
        </Route>

        <Route path={ROUTES.MAP}>
          <HomeMap />
        </Route>

        <Route
          path={ROUTES.HOME}
          exact
          render={props => <Home {...props} membershipType={membershipType} />}
        />
      </Switch>
    </Suspense>
  )
}

export default RouterConfig
