import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthContext } from '../../Auth/Auth'

import * as ROUTES from '../../NavigationComponent/constants'
// icons
import ProviderIcon from '../Icon/Provider'
import MapsIcon from '../Icon/Maps'
import PatientIcon from '../Icon/Patient'
import VisitsIcon from '../Icon/Visits'
import Billing from '../Icon/Billing'
import AppointmentIcon from '../Icon/Appointment'
import FacilityIcon from '../Icon/Facilities'
import PaymentIcon from '../Icon/Payment'
import DocumentIcon from '../Icon/Document'
import NotificationsIcon from '../Icon/Notifications'
import SettingsIcon from '../Icon/Settings'
import ExitAppIcon from '../Icon/ExitApp'
import HomeIcon from '../Icon/Home'

export const SideNavLinks = ({ setToggleNav }) => {
  const { logout } = useAuth0()
  const userMetadata = useContext(AuthContext)
  const isProvider = Number(userMetadata?.membershipType) === 3001

  const closeSideBar = () => setToggleNav(false)
  return (
    <>
      <div className="sidenav__items mb-8">
        <NavLink
          to={ROUTES.HOME}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <HomeIcon className="icon" />
          <span className="name">Home</span>
        </NavLink>
		<div className="sidenav__items__item"><center>Relationships</center></div>
        <NavLink
          to={ROUTES.REPORTERS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <MapsIcon className="icon" />
          <span className="name">Reporters</span>
        </NavLink>

        {isProvider && (
          <NavLink
            to={ROUTES.EDITORS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
            onClick={closeSideBar}
          >
            <PatientIcon />
            <span className="name">Editors</span>
          </NavLink>
        )}

        <NavLink
          to={ROUTES.BROADCASTERS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <AppointmentIcon className="icon" />
          <span className="name">Broadcasters</span>
        </NavLink>

        <NavLink
          to={ROUTES.PRODUCERS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <VisitsIcon className="icon" />
          <span className="name">Producers</span>
        </NavLink>

        <NavLink
          to={ROUTES.AUDIENCE}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <ProviderIcon className="icon" />
          <span className="name">Audience</span>
        </NavLink>
		<div className="sidenav__items__item"><center>Flow</center></div>

        <NavLink
          to={ROUTES.ARTICLES}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <FacilityIcon className="icon" />
          <span className="name">Articles</span>
        </NavLink>

        
          <NavLink
            to={ROUTES.NOTIFICATIONS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
            onClick={closeSideBar}
          >
            <PaymentIcon className="icon" />
            <span className="name">Notifications</span>
          </NavLink>
        
          <NavLink
            to={ROUTES.PROJECTS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
            onClick={closeSideBar}
          >
            <Billing className="icon" />
            <span className="name">Projects</span>
          </NavLink>
    

        <NavLink
          to={ROUTES.WORKFLOW}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <DocumentIcon className="icon" />
          <span className="name">Workflow</span>
        </NavLink>
        <NavLink
          to={ROUTES.WORKFLOW_EVENTS}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <DocumentIcon className="icon" />
          <span className="name">Workflow Events</span>
        </NavLink>

		<div className="sidenav__items__item"><center>Content</center></div>
	 			  
        <NavLink
          to={ROUTES.CONTENT}
          className="sidenav__items__item"
          activeClassName="sidenav__items__item--active"
          onClick={closeSideBar}
        >
          <NotificationsIcon className="icon" />
          <span className="name">Content</span>
        </NavLink> 

          <NavLink
            onClick={closeSideBar}
            to={ROUTES.COMPONENTS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Components</span>
          </NavLink>

          <NavLink
            onClick={closeSideBar}
            to={ROUTES.APPS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Apps</span>
          </NavLink>

          <NavLink
            onClick={closeSideBar}
            to={ROUTES.NETWORK_CONTENT}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Network Content</span>
          </NavLink>

          <NavLink
            onClick={closeSideBar}
            to={ROUTES.RATINGS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Ratings</span>
          </NavLink>
		<div className="sidenav__items__item"><center>Programming</center></div>

          <NavLink
            onClick={closeSideBar}
            to={ROUTES.PROGRAMS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Programs</span>
          </NavLink>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.SEGMENTS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Segments</span>
          </NavLink>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.SHOWS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Shows</span>
          </NavLink>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.SCHEDULER}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Scheduler</span>
          </NavLink>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.SERVICES}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Services</span>
          </NavLink>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.SPECTRUM}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Spectrum</span>
          </NavLink>
		<div className="sidenav__items__item"><center>Clock/Sync</center></div>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.MASTER_CLOCK}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Master Clock</span>
          </NavLink>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.TIMEBASE}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Time Base</span>
          </NavLink>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.SHOWS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Shows</span>
          </NavLink>
		<div className="sidenav__items__item"><center>Data</center></div>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.TRIGGERS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Triggers</span>
          </NavLink>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.TRANSFORMATIONS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Transformations</span>
          </NavLink>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.CONNECTORS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Connectors</span>
          </NavLink>
          <NavLink
            onClick={closeSideBar}
            to={ROUTES.DATA_ANALYTICS}
            className="sidenav__items__item"
            activeClassName="sidenav__items__item--active"
          >
            <SettingsIcon className="icon" />
            <span className="name">Analytics</span>
          </NavLink>


        <NavLink
          to={ROUTES.LOGOUT}
          style={{ marginTop: '20px' }}
          onClick={() => logout()}
          className="sidenav__items__item mt-20"
        >
          <ExitAppIcon className="icon" />
          <span className="name">Logout</span>
        </NavLink>
      </div>
    </>
  )
}