import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // log error-to-service
    console.log(error, errorInfo)
  }

  render() {
    return (
      <div>
        {this.state.hasError ? 
        <>
          <h2>Oops, Something went wrong. Go to <Link to='/'>Home</Link></h2>
        </> :
        this.props.children
        }
      </div>
    )
  }
}

export default ErrorBoundary