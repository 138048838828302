import React from 'react'

const PatientIcon = props => (
  <svg
    className="ionicon"
    viewBox="0 0 512 512"
    {...props}
    width={24}
    height={24}
  >
    <title>{'Pulse'}</title>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={30}
      d="M48 320h64l64-256 64 384 64-224 32 96h64"
    />
    <circle
      cx={432}
      cy={320}
      r={32}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
    />
  </svg>
)

export default PatientIcon
